import React, { useState } from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import SEO from "../components/SEO"
import { chunk } from "./../../src/util/helper"
// import BlogCard from "./../../src/components/core/BlogCard"
import H2 from "../components/core/H2"
import Layout from "../Layout"
const BlogCard = loadable(() => import("./../../src/components/core/BlogCard"))

// import "aos/dist/aos.css"

const TagTemplate = ({ data, location }) => {
  const { allWpPost, author } = data
  //  eslint-disable-next-line no-unused-vars
  const [totalCount, setTotalCount] = useState(allWpPost.nodes.length)
  const [postsToShow, setPostsToShow] = useState(12)
  const handleLoadMore = e => {
    setPostsToShow(postsToShow + 12)
  }
  const posts = allWpPost.nodes.map(e => e)

  return (
    <>
      <SEO title={author.seo.title}
        desc={author.seo.metaDesc}
        canonical={`https://webmobtech.com${location.pathname}`} />
      <Layout>
        <div className="relative bg-transperant pb-20 px-4 sm:px-6 lg:pt-40 pt-32 lg:pb-28 lg:px-8">
          {/* <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3"></div>
        </div> */}
          <div className="relative max-w-7xl mx-auto">
            <div className="text-center">
              <H2
                // className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
                title={`${author.firstName} ${author.lastName}`}
              />
              {/* <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              Author: {author.firstName} {author.lastName}
            </h2> */}
            </div>
            <div className="mt-12 grid gap-16  max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
              {chunk(posts.slice(0, postsToShow), 3).map(e =>
                e.map(f => <BlogCard {...f} />)
              )}
            </div>
            {postsToShow <= totalCount && (
              <div className={`mt-24  flex justify-center `}>
                <button
                  aria-hidden="true"
                  onClick={handleLoadMore}
                  className="bg-blue-500 inline-flex relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded"
                >
                  See more
                </button>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default TagTemplate

export const query = graphql`
  query($databaseId: Int!) {
    allWpPost: allWpPost(
      filter: { authorDatabaseId: { eq: $databaseId } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        date(formatString: "MMM DD, YYYY")
        uri
        excerpt
        featuredImage {
          node {
            mediaItemUrl
          }
        }
        author {
          node {
            avatar {
              url
            }
            name
            uri
          }
        }
        categories {
          nodes {
            name
            link
          }
        }
      }
    }
    author: wpUser(databaseId: { eq: $databaseId }) {
      seo {
        title
        metaDesc
      }
      firstName
      lastName
    }
  }
`
